
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "ProgressBar",
  components: {},
})
export default class ProgressBar extends Vue {
  @Prop({ default: 0 }) private percent!: number;
  @Prop({ default: "%" }) private unit!: string;
  @Prop({ default: "" }) private message!: string;
  @Prop({ default: "" }) private cssClass!: string;

  get wrapperClasses(): string[] {
    let classes = ["progress-bar"];
    classes.push(this.$mq);
    classes.push(this.cssClass);
    return classes;
  }
}
