
declare var PLATFORM_DOMAIN: any;
declare var CONNECT_DOMAIN: any;
import CircleSpinnerToCheckmark from "@/components/spinners/CircleSpinnerToCheckmark.vue";
import FadeSpinner from "@/components/spinners/FadeSpinner.vue";
import { CloudActivationPaymentTypes, CloudActivationProps } from "@/types/localtypes";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Alert, ContentNode } from "@/types/layout/contentNode";
import SimpleIconTitle from "@/components/common/SimpleIconTitle.vue";
import SimpleAlert from "@/components/common/SimpleAlert.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";

@Component({
  name: "CloudServerActivationFlow",
  components: {
    CircleSpinnerToCheckmark,
    FadeSpinner,
    SimpleIconTitle,
    SimpleAlert,
    ProgressBar,
  },
})
export default class CloudServerActivationFlow extends Vue {
  @Prop() private serverName!: CloudActivationProps["serverName"];
  @Prop() private cloudProvider!: CloudActivationProps["cloudProvider"];
  @Prop() private regionName!: CloudActivationProps["regionName"];
  @Prop() private instanceUrl!: CloudActivationProps["instanceUrl"];
  @Prop({ default: false })
  private isReady!: CloudActivationProps["isReady"];
  @Prop({ default: false })
  private isDelayed!: CloudActivationProps["isDelayed"];
  @Prop({ default: 0 })
  private elapsedTime!: CloudActivationProps["elapsedTime"];
  @Prop() private ssoType!: CloudActivationProps["ssoType"];
  @Prop() private platformRegistrationLink!: CloudActivationProps["platformRegistrationLink"];
  @Prop() private paymentType!: CloudActivationProps["paymentType"];

  displayMeta = false;
  domLoaded = false;
  progressBarPercent: number = 0;
  minSecondsBeforeRedirect: number = 60;
  delaySeconds: number = this.minSecondsBeforeRedirect / 100;
  activationCode = "";

  mounted() {
    this.setProgressBarInitialValue();
    setTimeout(() => {
      this.domLoaded = true;
    }, 0);
    this.increaseProgressBarPercent();
  }

  setProgressBarInitialValue() {
    let query = window.location.search;
    query = query.substring(query.indexOf("ac=") + 3);
    this.activationCode = query;
    let progressBarInitial = localStorage.getItem(query);
    if (progressBarInitial && parseInt(progressBarInitial) > 0) {
      this.progressBarPercent = parseInt(progressBarInitial);
    } else {
      this.progressBarPercent = 0;
    }
  }

  get showEnterpriseNotifyData() {
    return this.paymentType === "ENTERPRISE_X_MONTHLY";
  }
  enterpriseNotifyData: Alert = {
    message: `When you log in, you may see some services disabled. Once we're done<br/> setting up, you'll see those services as well.`,
    type: "warn",
  };
  get isMobile() {
    return this.$mq === "mobile";
  }

  get isTablet() {
    return this.$mq === "tablet";
  }

  get titleText() {
    return `Your DevOps & Security Journey is About to Begin`;
  }

  get purchaseTitle() {
    return `Thank You!<br/>Your Purchase Was Successful.`;
  }
  get showPurchaseTitle() {
    return ["ENTERPRISE_X_MONTHLY", "PRO_TEAM_MONTHLY"].includes(this.paymentType as CloudActivationPaymentTypes);
  }

  get subtitleText() {
    switch (this.paymentType) {
      case "TRIAL_ENTERPRISE":
        return "Your JFrog Trial environment is being created";
      case "PRO_TEAM_MONTHLY":
        return "Your JFrog Pro environment is being created";
      case "ENTERPRISE_X_MONTHLY":
        return "Your JFrog EnterpriseX environment is being created";
    }
  }

  get progressBarMessage() {
    return "This typically takes less than one minute.";
  }

  get serverData(): Array<ContentNode> {
    return [
      {
        title: "Hostname",
        value: this.serverName,
        icon: "activation_server.svg",
      },
      {
        title: "Location",
        value: this.cloudProvider + " in " + this.regionName,
        icon: "activation_location.svg",
      },
    ];
  }

  get boxesData(): Array<ContentNode> {
    return [
      {
        title: "Premium Security Tools",
        value: "Automated supply chain security and compliance tightly woven into your SDLC",
        icon: "activation_scan.svg",
      },
      {
        title: "Universal Binary Management",
        value: "Centrally manage all builds, packages, and dependencies in a single source of truth",
        icon: "activation_repo.svg",
      },
      {
        title: "Enterprise Scale and Control",
        value: "Create software anywhere with central control over how it’s accessed and used",
        icon: "activation_pipelines.svg",
      },
    ];
  }

  alertData: Alert = {
    message: "Activation is taking longer than expected, an email will be sent once the environment is ready.",
    type: "warn",
  };

  get showAlert() {
    return this.isDelayed || (this.progressBarPercent > 89 && !this.isReady);
  }

  goToPlatform() {
    if (this.platformRegistrationLink && this.platformRegistrationLink.length > 0) {
      window.location.href = this.platformRegistrationLink;
    } else {
      let url = this.instanceUrl || `https://${this.serverName}.${PLATFORM_DOMAIN}`;
      url += "/ui/login";

      if (url.indexOf("?") > -1) {
        url += "&firstRedirect=true";
      } else {
        url += "?firstRedirect=true";
      }

      if (this.ssoType) {
        url += "&auth_method=" + this.ssoType;
      }

      window.location.href = url;
    }
  }

  increaseProgressBarPercent() {
    if (this.progressBarPercent > 99 && this.isReady) {
      this.goToPlatform();
    }
    if (this.progressBarPercent < 90 && !this.isDelayed) {
      this.progressBarPercent++;
    }
    if (this.isReady) {
      this.progressBarPercent += 20;
    }
    if (this.progressBarPercent > 100) {
      this.progressBarPercent = 100;
    }
    localStorage.setItem(this.activationCode, this.progressBarPercent.toString());
    setTimeout(() => {
      this.increaseProgressBarPercent();
    }, this.delaySeconds * 1000);
  }
}
